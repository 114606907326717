// This puts the default image wrapping and ratio for grid-image-wrapper classes
@mixin default-image-fit() {
  .grid-image-wrapper {
    display: flex;
    // This one is because Safari has a bug with flexed items, the default is
    // align-items `stretched`, so it's required to reset it.
    align-items: flex-start;
  }

  .grid-image-wrapper::before {
    padding-top: 60%;
    content: '';
    float: left;
  }

  .grid-image-wrapper::after {
    display: block;
    clear: both;
    content: '';
  }

  .grid-image-wrapper {
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@mixin default-aspect-ratio() {
  height: auto; // needed width width, height props and aspect ratio
  aspect-ratio: $aspect-ratio;
  object-fit: cover;
  object-position: top left;
}

@mixin word-break() {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
