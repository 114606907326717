#footer {
  margin-top: $footer-vertical-spacing-top;
  text-align: center;

  .footer {
    padding: 4.2rem 2rem;
    background-color: $lightgrey;
    font-size: 18px;

    a.powered-by {
      color: $blue-for-grey-contrast;
      font-size: 14px;
    }
    .footer-branding {
      font-size: 14px;
    }

    .footer-message {
      font-weight: $bold;
      a {
        color: $blue-for-grey-contrast;
        font-weight: inherit;
        text-decoration: underline;
      }
    }

    ul {
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-top: 1.4rem;
      list-style: none;

      li {
        padding: 0 7px;
        border-right: 1px solid #205c90;
        font-size: 18px;

        &:last-of-type {
          border: none;
        }
        a {
          color: $blue-for-grey-contrast;
        }
      }
    }

    .logo {
      margin: 5rem 0 1.8rem 0;
    }
  }
}
