.table-of-contents {
  .ui.bulleted.list {
    li {
      a {
        text-decoration: underline;
      }

      &::before {
        color: $blue-for-grey-contrast !important;
      }
    }
  }
}
