@import 'utils';

$white: #fff;
$black: #000;
$darkgrey: #666;
$grey: #ececec;
$grey3: #b2b2b2;
$grey4: #878786;
$medium-blue: #55b8da !default;
$dark-blue: #006489 !default;
$light-orange: #fddf63 !default;
$light-green: #c9d465 !default;
$dark-green: #7da048 !default;

$highlight-custom-color-1: var(--highlight-custom-color-1, $medium-blue);
$highlight-custom-color-2: var(--highlight-custom-color-2, $dark-blue);
$highlight-custom-color-3: var(--highlight-custom-color-3, $light-orange);
$highlight-custom-color-4: var(--highlight-custom-color-4, $light-green);
$highlight-custom-color-5: var(--highlight-custom-color-5, $dark-green);

$highlight-images-aspect-ratio: var(--highlight-images-aspect-ratio, 4/3);
$highlight-images-object-position: var(
  --highlight-images-object-position,
  top left
);

.block.highlight {
  .ui.container.padded {
    padding: 0 40px;
  }

  .teaser-item,
  .grid-teaser-item {
    flex-direction: column;
  }

  .teaser-item.top {
    a {
      display: inline;
    }

    .highlight-image-wrapper {
      line-height: 0;

      img {
        width: 100%;
        height: auto; // important with width+height img attributes
        aspect-ratio: $highlight-images-aspect-ratio;
        object-fit: cover;
        object-position: $highlight-images-object-position;
      }
    }

    .highlight-description {
      padding-bottom: 100px;
      margin-top: -7px;

      .title {
        margin-top: 75px;

        h2 {
          font-size: 48px;
          font-weight: 700;
          line-height: 56px;
          @include word-break();
        }
      }

      .description {
        margin-top: 50px;
        margin-bottom: 50px;

        p {
          font-size: 24px;
          font-weight: 300;
          line-height: 34px;
        }
      }

      .button {
        a {
          display: initial;
          padding: 10px 30px 15px;
          border: solid;
          border-width: 1px;
          border-radius: 0;
          color: inherit !important;
          font-size: 16px;

          font-weight: 700;
          line-height: 30px;
          text-align: center;
        }
      }

      li {
        a {
          text-decoration: underline;
        }
      }
    }
  }

  &.has--descriptionColor--highlight-custom-color-1 {
    .highlight-description {
      background: $highlight-custom-color-1;
      color: $black;

      h2,
      a,
      p {
        color: $black;
      }
    }
  }

  &.has--descriptionColor--highlight-custom-color-2 {
    .highlight-description {
      background: $highlight-custom-color-2;
      color: $white;

      h2,
      a,
      p {
        color: $white;
      }
    }
  }

  &.has--descriptionColor--highlight-custom-color-3 {
    .highlight-description {
      background: $highlight-custom-color-3;
      color: $black;

      h2,
      a,
      p {
        color: $black;
      }
    }
  }

  &.has--descriptionColor--highlight-custom-color-4 {
    .highlight-description {
      background: $highlight-custom-color-4;
      color: $black;

      h2,
      a,
      p {
        color: $black;
      }
    }
  }

  &.has--descriptionColor--highlight-custom-color-5 {
    .highlight-description {
      background: $highlight-custom-color-5;
      color: $black;

      h2,
      a,
      p {
        color: $black;
      }
    }
  }
}

// TODO: merge with above?
// Block Highlight Edit
#page-edit,
#page-add {
  .block-editor-highlight {
    // The selection of text clashes with the default ::selection,
    // replacing it with a color more neutral on top of solid color
    ::selection {
      background-color: #ccc !important;
    }

    &.has--descriptionColor--highlight-custom-color-1 {
      .highlight-description {
        background: $highlight-custom-color-1;
        color: $black;

        h2,
        a,
        p {
          color: $black;
        }
      }
    }

    &.has--descriptionColor--highlight-custom-color-2 {
      .highlight-description {
        background: $highlight-custom-color-2;
        color: $white;

        h2,
        a,
        p {
          color: $white;
        }
      }
    }

    &.has--descriptionColor--highlight-custom-color-3 {
      .highlight-description {
        background: $highlight-custom-color-3;
        color: $black;

        h2,
        a,
        p {
          color: $black;
        }
      }
    }

    &.has--descriptionColor--highlight-custom-color-4 {
      .highlight-description {
        background: $highlight-custom-color-4;
        color: $black;

        h2,
        a,
        p {
          color: $black;
        }
      }
    }

    &.has--descriptionColor--highlight-custom-color-5 {
      .highlight-description {
        background: $highlight-custom-color-5;
        color: $black;

        h2,
        a,
        p {
          color: $black;
        }
      }
    }
  }

  .block.highlight {
    max-width: 1440px;

    .no-image-wrapper {
      display: inline-block;
      text-align: center;
    }
  }
}

.color-picker-widget {
  // Color widget Highlight-Block

  .button.highlight-custom-color-1,
  .button.highlight-custom-color-1.active,
  .button.highlight-custom-color-1:hover,
  .button.active.highlight-custom-color-1:hover {
    background: $highlight-custom-color-1;
  }

  .button.highlight-custom-color-2,
  .button.highlight-custom-color-2.active,
  .button.highlight-custom-color-2:hover,
  .button.active.highlight-custom-color-2:hover {
    background: $highlight-custom-color-2;
  }

  .button.highlight-custom-color-3,
  .button.highlight-custom-color-3.active,
  .button.highlight-custom-color-3:hover,
  .button.active.highlight-custom-color-3:hover {
    background: $highlight-custom-color-3;
  }

  .button.highlight-custom-color-4,
  .button.highlight-custom-color-4.active,
  .button.highlight-custom-color-4:hover,
  .button.active.highlight-custom-color-4:hover {
    background: $highlight-custom-color-4;
  }

  .button.highlight-custom-color-5,
  .button.highlight-custom-color-5.active,
  .button.highlight-custom-color-5:hover,
  .button.active.highlight-custom-color-5:hover {
    background: $highlight-custom-color-5;
  }
}
